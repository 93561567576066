import { NavLink } from "@remix-run/react";
import classNames from "classnames";
import { MobileBurgerMenu } from "./components/MobileBurgerMenu";
import { useState } from "react";
import { Icon } from "@portal-frontend-ssr/ui";
import deadlockLogo from "./assets/deadlock-blast.svg";
import discordClyde from "./assets/discord-clyde.svg";

const JoinDiscordDesktop = () => (
  <a
    href="https://discord.gg/Q782HHuMcQ"
    target="_blank"
    className="rounded-small group relative flex h-10 h-fit w-10 cursor-pointer gap-2 bg-[#5865F2] p-3 transition-all hover:w-40"
  >
    <span className="font-style-label-l4 whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:transition-opacity group-hover:delay-100 group-hover:duration-200">
      Join discord
    </span>
    <img src={discordClyde} className="absolute right-3 w-4" />
  </a>
);
const JoinDiscordMobile = () => (
  <a
    href="https://discord.gg/Q782HHuMcQ"
    target="_blank"
    className="rounded-small flex size-7 items-center justify-center bg-[#5865F2]"
  >
    <img src={discordClyde} className="w-4" />
  </a>
);

const navItemMap: Record<string, string> = {
  "/users": "User Stats",
  "/matches": "Matches",
  "/heroes": "Heroes",
  "/leaderboard": "Leaderboard",
  "/patches": "Patches",
};

export const DeadlockNavbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="z-navbar bg-background-100 max-sd:h-11 font-style-label-l4 fixed top-0 h-[3.75rem] w-screen px-4 md:px-7 sd:px-5">
      {/* Mobile navbar */}
      <div className="sd:hidden flex h-full items-center">
        <button
          aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          onClick={() => setIsMobileMenuOpen((current) => !current)}
        >
          <Icon icon={isMobileMenuOpen ? "closeCircled" : "burgerMenu"} className="size-6" />
        </button>
        <div className="flex grow justify-center">
          <NavLink
            to="/"
            className={classNames(
              "rounded-small flex h-fit items-center gap-2 p-2",
              "transition-opacity delay-100 duration-200 ease-in-out",
              {
                "opacity-0": isMobileMenuOpen,
              },
            )}
          >
            <img src={deadlockLogo} className="size-5" />
            <span>Deadlock</span>
          </NavLink>
        </div>
        <JoinDiscordMobile />
      </div>
      {/* Desktop navbar */}
      <div className="flex justify-between items-center h-full hidden sd:flex w-full ">
        <div className="flex h-full items-center gap-5 ">
          <NavLink to="/" className=" rounded-small hover:opacity-80 flex relative h-fit items-center gap-2 py-2.5">
            <img src={deadlockLogo} className="size-4" />
            <div className="flex flex-col">
              <div>Deadlock</div>
            </div>
          </NavLink>
          <div className="h-8 w-px bg-neutral/50" />
          {Object.entries(navItemMap).map(([key, title]) => {
            return (
              <NavLink
                key={key}
                to={key}
                className={({ isActive }) =>
                  classNames("hover:text-foreground-95 flex h-full items-center border-y-2 py-5 border-transparent", {
                    "border-b-primary-100": isActive,
                  })
                }
              >
                {title}
              </NavLink>
            );
          })}
        </div>
        <JoinDiscordDesktop />
      </div>

      {/* <div className="bg-background-90 sd:h-8 max-sd:top-11 absolute top-[3.75rem] flex h-11 w-screen items-center justify-center">
        <p className="font-style-body-b4 sd:font-style-body-b3 text-foreground-90 sd:px-20 px-12 py-2 text-center normal-case">
          Currently we're not tracking all matches. Have questions, feedback or an issue?{" "}
          <a className="text-primary-100 cursor-pointer" href="https://discord.gg/Q782HHuMcQ" target="_blank">
            Join our Discord
          </a>{" "}
          and let us know!
        </p>
      </div> */}
      <MobileBurgerMenu navbarItems={navItemMap} isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </nav>
  );
};
