import { Transition } from "@headlessui/react";
import { NavLink } from "@remix-run/react";
import classNames from "classnames";
import deadlockLogo from "../assets/deadlock-blast.svg";

export const MobileBurgerMenu = ({
  isOpen,
  navbarItems,
  onClose,
}: {
  isOpen: boolean;
  navbarItems: Record<string, string>;
  onClose: () => void;
}) => {
  return (
    <Transition
      as="div"
      show={isOpen}
      enter="transition-opacity duration-200 ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity delay-100 duration-200 ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classNames("h-[calc(100vh-2.75rem)] w-full backdrop-blur-sm")}
      onClick={() => onClose()}
    >
      <Transition
        as="div"
        show={isOpen}
        enter="transition-transform duration-200 ease-in-out"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-200 ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={classNames(
          "bg-background-100 scrollbar-none flex h-full w-80 flex-col items-start gap-9 overflow-hidden overflow-y-auto px-7 pb-24 pt-7 transition-all duration-300 ease-in-out",
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <NavLink
          to="/"
          onClick={onClose}
          className={({ isActive }) =>
            classNames("font-style-label-l2 border-y-2 border-transparent pb-2 transition-all", {
              "border-b-primary-100": isActive,
            })
          }
        >
          Home
        </NavLink>

        {Object.entries(navbarItems).map(([key, title]) => {
          return (
            <NavLink
              to={key}
              key={key}
              onClick={onClose}
              className={({ isActive }) =>
                classNames("font-style-label-l2 border-y-2 border-transparent pb-2 transition-all", {
                  "border-b-primary-100": isActive,
                })
              }
            >
              {title}
            </NavLink>
          );
        })}
      </Transition>
    </Transition>
  );
};
